import './manage-wedding-my-plans.styles.scss';
import React, { useEffect, useState } from 'react';
import axiosInstance from '../../api/axiosInstance';
import ManageWeddingPlanCard from '../manage-wedding-plan-card/manage-wedding-plan-card.component';
import { PathList } from '../../data/nav-link.data';
import { useTranslation } from 'react-i18next'; // Importing i18n
import { useSelector } from 'react-redux';

// Define phase lists
export const PhaseLists = ['proposal', 'before', 'wedding_ceremony', 'after'];

// Define params for routes
export const ManageWeddingParams = {
  proposal: 'proposal',
  before: 'before-wedding',
  wedding: 'wedding-ceremony',
  after: 'after-wedding',
};

// Move formatEventList outside of the component
const formatEventList = (data, phase, language) => {
  return data
    .filter((option) => option.wedding_option.wedding_phase.translation.name_en === phase)
    .map((option) => {
      const translation = option.wedding_option.translation;
      const themeTranslation = option.wedding_option.wedding_type.translation;

      return {
        title: getTranslation(translation, language),
        theme: getTranslation(themeTranslation, language),
      };
    });
};

// Helper function to get translation based on current language
const getTranslation = (translationObj, language) => {
  switch (language) {
    case 'en':
      return translationObj.name_en || translationObj.description_en;
    case 'cn':
      return translationObj.name_cn || translationObj.description_cn;
    case 'jp':
      return translationObj.name_jp || translationObj.description_jp;
    case 'my':
      return translationObj.name_my || translationObj.description_my;
    default:
      return translationObj.name_en; // Fallback to English
  }
};

const ManageWeddingMyPlans = () => {
  const { t, i18n } = useTranslation(); // Get i18n functions
  const [weddingPlans, setWeddingPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const user = useSelector((state) => state.auth.user); // Get user from Redux

  useEffect(() => {
    const fetchWeddingPlans = async () => {
      try {
        if (!user) return; // Ensure we have a user ID before making the request

        // Fetch wedding options for the user
        const response = await axiosInstance.get(`/user-wedding-options/${user.id}`);
        
        // Transform the response data to match the structure of dummy data
        const transformedData = [
          {
            phase: PhaseLists[0],
            param: ManageWeddingParams.proposal,
            title: t('proposal'),
            eventList: formatEventList(response.data, 'Proposal', i18n.language),
            totalBudget: 0.00,
          },
          {
            phase: PhaseLists[1],
            param: ManageWeddingParams.before,
            title: t('before_wedding'),
            eventList: formatEventList(response.data, 'Before', i18n.language),
            totalBudget: 0.00,
          },
          {
            phase: PhaseLists[2],
            param: ManageWeddingParams.wedding,
            title: t('wedding_ceremony'),
            eventList: formatEventList(response.data, 'Wedding Ceremony', i18n.language),
            totalBudget: 0.00,
          },
          {
            phase: PhaseLists[3],
            param: ManageWeddingParams.after,
            title: t('after_wedding'),
            eventList: formatEventList(response.data, 'After', i18n.language),
            totalBudget: 0.00,
          },
        ];

        setWeddingPlans(transformedData);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch wedding plans.');
        setLoading(false);
      }
    };

    fetchWeddingPlans();
  }, [user, i18n.language, t]); // Now no need to add formatEventList as a dependency

  if (loading) return <div>{t('loading')}</div>;
  if (error) return <div>{t('error_loading')}</div>;

  return (
    <>
      <div className="main-title">{t('my_plans')}</div>          
      <div className="grid-container">
        {
          weddingPlans &&
          weddingPlans.map((data) => (
            <ManageWeddingPlanCard
              key={data.phase}
              to={`/${PathList.manageWedding.pathname}/${data.param}`}
              phase={data.phase}
              phaseTitle={data.title}
              eventList={data.eventList}
              total={data.totalBudget}
            />
          ))
        }
      </div>
    </>
  );
}

export default ManageWeddingMyPlans;
