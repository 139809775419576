import AuthActionTypes from './auth.types';


const { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, UPDATE_USER_DATA } = AuthActionTypes;

const initialState = {
  token: localStorage.getItem('token') || null,
  user: JSON.parse(localStorage.getItem('user')) || null,
  error: null,
  isLoading: false,
  rememberMe: localStorage.getItem('rememberMe') === 'true',
  savedCredentials: {
    username: localStorage.getItem('username') || '',
    password: localStorage.getItem('password') || '',
  },
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        fill_questionnaire: action.payload.user.fill_questionnaire,
        error: null,
        isLoading: false,
        rememberMe: action.payload.rememberMe,
        savedCredentials: action.payload.rememberMe
          ? {
              username: action.payload.username,
              password: action.payload.password,
            }
          : { username: '', password: '' },
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case LOGOUT:
      return {
        ...state,
        token: null,
        user: null,
        isLoading: false,
        error: null,
        savedCredentials: state.rememberMe
          ? state.savedCredentials
          : { username: '', password: '' },
      };
    case UPDATE_USER_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload, // Merge updated data into the user object
        },
      };
    default:
      return state;
  }
};

export default authReducer;
