import React from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import LayoutMain from '../components/shared/layout-main/layout-main.component';
import ProtectedRoute from '../components/shared/protected-route/protected-route.component';
import Home from '../pages/home/home.page';
import NotFound from '../pages/not-found/not-found.page';
import Login from '../pages/login/login.page';
import SignUp from '../pages/sign-up/sign-up.page';
import ForgetPassword from '../pages/forget-password/forget-password.page';
import TermsAndConditions from '../pages/terms-and-conditions/terms-and-conditions.page';
import { PathList } from '../data/nav-link.data';
import ManageWedding from '../pages/manage-wedding/manage-wedding.page';
import { lazy, Suspense, useEffect } from 'react';
import DefaultLoading from '../components/shared/default-loading/default-loading.component';
import { useSelector } from 'react-redux';

const Questionnaire = lazy(() => import('../pages/questionnaire/questionnaire.page'));

const AppRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation(); // To get the current location
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    // If user is authenticated and `fill_questionnaire` exists
    if (user && location.pathname === PathList.myWedding.pathname) {
      if (user.fill_questionnaire === 0) {
        // If questionnaire not filled, redirect to Questionnaire
        navigate(PathList.myWedding.pathname);
      } else if (user.fill_questionnaire === 1) {
        // If questionnaire is filled, redirect to ManageWedding
        navigate(PathList.manageWedding.pathname);
      }
    }
  }, [user, location.pathname, navigate]);

  return (
    <Suspense fallback={<DefaultLoading />}>
      <Routes>
        <Route path="/" element={<LayoutMain />}>
          <Route index element={<Home />} />
          <Route
            path={PathList.myWedding.pathname}
            element={
              <ProtectedRoute>
                <Questionnaire />
              </ProtectedRoute>
            }
          />
          <Route
            path={PathList.myWedding.pathname + '/:page'}
            element={
              <ProtectedRoute>
                <Questionnaire />
              </ProtectedRoute>
            }
          />

          <Route path={PathList.login.pathname} element={<Login />} />
          <Route path={PathList.signup.pathname} element={<SignUp />} />
          <Route path={PathList.forgetPassword.pathname} element={<ForgetPassword />} />
          <Route path={PathList.termAndCondition.pathname} element={<TermsAndConditions />} />
          <Route
            path={PathList.manageWedding.pathname}
            element={
              <ProtectedRoute>
                <ManageWedding />
              </ProtectedRoute>
            }
          />
          <Route
            path={PathList.manageWedding.pathname + '/:phase'}
            element={
              <ProtectedRoute>
                <ManageWedding />
              </ProtectedRoute>
            }
          />
          <Route
            path={PathList.manageWedding.pathname + '/:phase/:event'}
            element={
              <ProtectedRoute>
                <ManageWedding />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
