import ManageWeddingBody from '../../components/manage-wedding-body/manage-wedding-body.component';
import ManageWeddingSchedule from '../../components/manage-wedding-schedule/manage-wedding-schedule.component';
import SubHeader from '../../components/shared/sub-header/sub-header.component';
import './manage-wedding.styles.scss';
import { useTranslation } from 'react-i18next'; // Import useTranslation

const ManageWedding = () => {
    const { t } = useTranslation(); // Hook to access i18n translation

    return (
        <div className="sign-up-body-container">
            <div className="sign-up-header-container">
                <SubHeader>
                    <div className="title-center">
                        <h1 className='main-header-title'>{t('manage_wedding')}</h1> 
                    </div>
                </SubHeader>
                <ManageWeddingBody />            
                <ManageWeddingSchedule />    
            </div>
        </div>
    )
}

export default ManageWedding;
