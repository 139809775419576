import { SET_QUESTIONNAIRE_OPTIONS, SET_EVENT_LIST, SET_SELECTED_THEME } from './questionnaire.action';

// questionnaire.reducer.js

const initialState = {
  questionnaireOptions: {
    'proposal': [],
    'before': [],
    'wedding': [],
    'after': [],
  },
  eventList: [],
  selectedTheme: 0, // Set default to 'All' with id 0
};

const questionnaireReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_QUESTIONNAIRE_OPTIONS:
      const { phase, options } = action.payload;
      return {
        ...state,
        questionnaireOptions: {
          ...state.questionnaireOptions,
          [phase.toString()]: options,
        },
      };
    case SET_EVENT_LIST:
      return {
        ...state,
        eventList: action.payload,
      };
    case 'APPEND_EVENT_LIST':
      return {
        ...state,
        eventList: [...state.eventList, ...action.payload],
      };
    case SET_SELECTED_THEME:
      return {
        ...state,
        selectedTheme: action.payload,
      };
    default:
      return state;
  }
};

export default questionnaireReducer;
