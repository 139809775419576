export const EventThemes = [
    {id: 'theme-1', title: 'Muslim'},
    {id: 'theme-2', title: 'Chinese'},
    {id: 'theme-3', title: 'Hindu'},
    {id: 'theme-4', title: 'Christian'},
    {id: 'theme-5', title: 'Japanese'},
]

export const ThemeCategories = [{id: 'theme-0', title: 'All'}, ...EventThemes]

export const PhaseLists = ['proposal', 'before', 'wedding_ceremony', 'after'];

export const EventLists = {
    // Proposal
    "p1_t1_01": { id: 'p1_t1_01', title: 'Discussion', formName: 'phase1_theme1_01', theme: EventThemes[0]['title'], infoId: 'info_p1_t1_01' }, // Muslim
    "p1_t1_02": { id: 'p1_t1_02', title: 'Engagement', formName: 'phase1_theme1_02', theme: EventThemes[0]['title'] },
    "p1_t2_01": { id: 'p1_t2_01', title: 'Betrothal', formName: 'phase1_theme2_01', theme: EventThemes[1]['title'] }, // Chinese
    "p1_t2_02": { id: 'p1_t2_02', title: 'Engagement', formName: 'phase1_theme2_02', theme: EventThemes[1]['title'] },
    "p1_t3_01": { id: 'p1_t3_01', title: 'Arranged Marriage', formName: 'phase1_theme3_01', theme: EventThemes[2]['title'] }, // Hindu
    "p1_t3_02": { id: 'p1_t3_02', title: 'Engagement', formName: 'phase1_theme3_02', theme: EventThemes[2]['title'] },
    "p1_t4_01": { id: 'p1_t4_01', title: 'Proposal', formName: 'phase1_theme4_01', theme: EventThemes[3]['title'] }, // Christian
    "p1_t4_02": { id: 'p1_t4_02', title: 'Engagement', formName: 'phase1_theme4_02', theme: EventThemes[3]['title'] },
    "p1_t5_01": { id: 'p1_t5_01', title: 'Proposal', formName: 'phase1_theme5_01', theme: EventThemes[4]['title'] }, // Japanese
    "p1_t5_02": { id: 'p1_t5_02', title: 'Introduction of Families', formName: 'phase1_theme5_02', theme: EventThemes[4]['title'] },

    // Before
    "p2_t1_01": { id: 'p2_t1_01', title: 'Marriage Course', formName: 'phase2_theme1_01', theme: EventThemes[0]['title'] }, // Muslim
    "p2_t1_02": { id: 'p2_t1_02', title: 'Application', formName: 'phase2_theme1_02', theme: EventThemes[0]['title'] },
    "p2_t1_03": { id: 'p2_t1_03', title: 'Permission', formName: 'phase2_theme1_03', theme: EventThemes[0]['title'] },
    "p2_t1_04": { id: 'p2_t1_04', title: 'Health Check-Up', formName: 'phase2_theme1_04', theme: EventThemes[0]['title'] },
    "p2_t2_01": { id: 'p2_t2_01', title: 'Choosing an Auspicious Date', formName: 'phase2_theme2_01', theme: EventThemes[1]['title'] }, // Chinese
    "p2_t2_02": { id: 'p2_t2_02', title: 'Preparing Registration', formName: 'phase2_theme2_02', theme: EventThemes[1]['title'] },
    "p2_t3_01": { id: 'p2_t3_01', title: 'Pre-Wedding Rituals', formName: 'phase2_theme3_01', theme: EventThemes[2]['title'] }, // Hindu
    "p2_t3_02": { id: 'p2_t3_02', title: 'Choosing an Auspicious Date', formName: 'phase2_theme3_02', theme: EventThemes[2]['title'] },
    "p2_t3_03": { id: 'p2_t3_03', title: 'Registration', formName: 'phase2_theme3_03', theme: EventThemes[2]['title'] },
    "p2_t4_01": { id: 'p2_t4_01', title: 'Pre-Marriage Counseling', formName: 'phase2_theme4_01', theme: EventThemes[3]['title'] }, // Christian
    "p2_t4_02": { id: 'p2_t4_02', title: 'Banns of Marriage', formName: 'phase2_theme4_02', theme: EventThemes[3]['title'] },
    "p2_t4_03": { id: 'p2_t4_03', title: 'Wedding Preparations', formName: 'phase2_theme4_03', theme: EventThemes[3]['title'] },
    "p2_t4_04": { id: 'p2_t4_04', title: 'Registration', formName: 'phase2_theme4_04', theme: EventThemes[3]['title'] },
    "p2_t5_01": { id: 'p2_t5_01', title: 'Engagement Ceremony', formName: 'phase2_theme5_01', theme: EventThemes[4]['title'] }, // Japanese
    "p2_t5_02": { id: 'p2_t5_02', title: 'Selecting a Date', formName: 'phase2_theme5_02', theme: EventThemes[4]['title'] },
    "p2_t5_03": { id: 'p2_t5_03', title: 'Wedding Preparations', formName: 'phase2_theme5_03', theme: EventThemes[4]['title'] },
    "p2_t5_04": { id: 'p2_t5_04', title: 'Registration', formName: 'phase2_theme5_04', theme: EventThemes[4]['title'] },

    // Wedding Ceremony
    "p3_t1_01": { id: 'p3_t1_01', title: 'Nikah Ceremony', formName: 'phase3_theme1_01', theme: EventThemes[0]['title'] }, // Muslim
    "p3_t1_02": { id: 'p3_t1_02', title: 'Reception', formName: 'phase3_theme1_02', theme: EventThemes[0]['title'] },
    "p3_t2_01": { id: 'p3_t2_01', title: 'Tea Ceremony', formName: 'phase3_theme2_01', theme: EventThemes[1]['title'] }, // Chinese
    "p3_t2_02": { id: 'p3_t2_02', title: 'Banquet', formName: 'phase3_theme2_02', theme: EventThemes[1]['title'] },
    "p3_t3_01": { id: 'p3_t3_01', title: 'Kanyadaan', formName: 'phase3_theme3_01', theme: EventThemes[2]['title'] }, // Hindu
    "p3_t3_02": { id: 'p3_t3_02', title: 'Saptapadi', formName: 'phase3_theme3_02', theme: EventThemes[2]['title'] },
    "p3_t3_03": { id: 'p3_t3_03', title: 'Tying the Thali', formName: 'phase3_theme3_03', theme: EventThemes[2]['title'] },
    "p3_t4_01": { id: 'p3_t4_01', title: 'Church Wedding', formName: 'phase3_theme4_01', theme: EventThemes[3]['title'] }, // Christian
    "p3_t4_02": { id: 'p3_t4_02', title: 'Signing the Registration', formName: 'phase3_theme4_02', theme: EventThemes[3]['title'] },
    "p3_t5_01": { id: 'p3_t5_01', title: 'Shinto Ceremony', formName: 'phase3_theme5_01', theme: EventThemes[4]['title'] }, // Japanese
    "p3_t5_02": { id: 'p3_t5_02', title: 'Western-Style Ceremony', formName: 'phase3_theme5_02', theme: EventThemes[4]['title'] },
    "p3_t5_03": { id: 'p3_t5_03', title: 'Reception', formName: 'phase3_theme5_03', theme: EventThemes[4]['title'] },

    // After
    "p4_t1_01": { id: 'p4_t1_01', title: 'Registration', formName: 'phase4_theme1_01', theme: EventThemes[0]['title'] }, // Muslim
    "p4_t1_02": { id: 'p4_t1_02', title: 'Marriage Certificate', formName: 'phase4_theme1_02', theme: EventThemes[0]['title'] },
    "p4_t1_03": { id: 'p4_t1_03', title: 'Name Change', formName: 'phase4_theme1_03', theme: EventThemes[0]['title'] },
    "p4_t2_01": { id: 'p4_t2_01', title: 'Post-Wedding Customs', formName: 'phase4_theme2_01', theme: EventThemes[1]['title'] }, // Chinese
    "p4_t3_01": { id: 'p4_t3_01', title: 'Reception', formName: 'phase4_theme3_01', theme: EventThemes[2]['title'] }, // Hindu
    "p4_t4_01": { id: 'p4_t4_01', title: 'Reception', formName: 'phase4_theme4_01', theme: EventThemes[3]['title'] }, // Christian
    "p4_t5_01": { id: 'p4_t5_01', title: 'Honeymoon', formName: 'phase4_theme5_01', theme: EventThemes[4]['title'] }, // Japanese
    "p4_t5_02": { id: 'p4_t5_02', title: 'Name Change', formName: 'phase4_theme5_02', theme: EventThemes[4]['title'] },
    "p4_t5_03": { id: 'p4_t5_03', title: 'Post-Wedding Visits', formName: 'phase4_theme5_03', theme: EventThemes[4]['title'] },

};

export const PhaseEventLists = {
    // Proposal
    [PhaseLists[0]]: {
        // theme-1 (Muslim)
        [EventThemes[0].id]: [
            'p1_t1_01', // Discussion
            'p1_t1_02', // Engagement
        ],
        // theme-2 (Chinese)
        [EventThemes[1].id]: [
            'p1_t2_01', // Betrothal
            'p1_t2_02', // Engagement
        ],
        // theme-3 (Hindu)
        [EventThemes[2].id]: [
            'p1_t3_01', // Arranged Marriage
            'p1_t3_02', // Engagement
        ],
        // theme-4 (Christian)
        [EventThemes[3].id]: [
            'p1_t4_01', // Proposal
            'p1_t4_02', // Engagement
        ],
        // theme-5 (Japanese)
        [EventThemes[4].id]: [
            'p1_t5_01', // Proposal
            'p1_t5_02', // Introduction of Families
        ],
    },
    // Before
    [PhaseLists[1]]: {
        // theme-1 (Muslim)
        [EventThemes[0].id]: [
            'p2_t1_01', // Marriage Course
            'p2_t1_02', // Application
            'p2_t1_03', // Permission
            'p2_t1_04', // Health Check-Up
        ],
        // theme-2 (Chinese)
        [EventThemes[1].id]: [
            'p2_t2_01', // Choosing an Auspicious Date
            'p2_t2_02', // Preparing Registration
        ],
        // theme-3 (Hindu)
        [EventThemes[2].id]: [
            'p2_t3_01', // Pre-Wedding Rituals
            'p2_t3_02', // Choosing an Auspicious Date
            'p2_t3_03', // Registration
        ],
        // theme-4 (Christian)
        [EventThemes[3].id]: [
            'p2_t4_01', // Pre-Marriage Counseling
            'p2_t4_02', // Banns of Marriage
            'p2_t4_03', // Wedding Preparations
            'p2_t4_04', // Registration
        ],
        // theme-5 (Japanese)
        [EventThemes[4].id]: [
            'p2_t5_01', // Engagement Ceremony
            'p2_t5_02', // Selecting a Date
            'p2_t5_03', // Wedding Preparations
            'p2_t5_04', // Registration
        ],
    },
    // Wedding Ceremony
    [PhaseLists[2]]: {
        // theme-1 (Muslim)
        [EventThemes[0].id]: [
            'p3_t1_01', // Nikah Ceremony
            'p3_t1_02', // Reception
        ],
        // theme-2 (Chinese)
        [EventThemes[1].id]: [
            'p3_t2_01', // Tea Ceremony
            'p3_t2_02', // Banquet
        ],
        // theme-3 (Hindu)
        [EventThemes[2].id]: [
            'p3_t3_01', // Kanyadaan
            'p3_t3_02', // Saptapadi
            'p3_t3_03', // Tying the Thali
        ],
        // theme-4 (Christian)
        [EventThemes[3].id]: [
            'p3_t4_01', // Church Wedding
            'p3_t4_02', // Signing the Registration
        ],
        // theme-5 (Japanese)
        [EventThemes[4].id]: [
            'p3_t5_01', // Shinto Ceremony
            'p3_t5_02', // Western-Style Ceremony
            'p3_t5_03', // Reception
        ],
    },
    // After
    [PhaseLists[3]]: {
        // theme-1 (Muslim)
        [EventThemes[0].id]: [
            'p4_t1_01', // Registration
            'p4_t1_02', // Marriage Certificate
            'p4_t1_03', // Name Change
        ],
        // theme-2 (Chinese)
        [EventThemes[1].id]: [
            'p4_t2_01', // Post-Wedding Customs
        ],
        // theme-3 (Hindu)
        [EventThemes[2].id]: [
            'p4_t3_01', // Reception
        ],
        // theme-4 (Christian)
        [EventThemes[3].id]: [
            'p4_t4_01', // Reception
        ],
        // theme-5 (Japanese)
        [EventThemes[4].id]: [
            'p4_t5_01', // Honeymoon
            'p4_t5_02', // Name Change
            'p4_t5_03', // Post-Wedding Visits
        ],
    },
};

export const EventInfoList = {
    'info_p1_t1_01': {id: 'info_p1_t1_01', content: "The proposal typically involves a discussion between families, where the groom's family approaches the bride's family." }
}
// export const GuideLists = {
//     [PhaseLists[0]]: {
//         [EventThemes[0]['id']]: [
//             {title: 'Discussion', content: "The proposal typically involves a discussion between families, where the groom's family approaches the bride's family."},
//             {title: 'Engagement (Pertunangan)', content: "The couple may become engaged (Bertunang), which includes an exchange of rings or gifts."},
//         ],
//         [EventThemes[1]['id']]: [
//             {title: 'Betrothal (Guo Da Li)', content: "The groom's family sends betrothal gifts to the bride's family."},
//             {title: 'Engagement', content: "The couple may exchange rings and hold a small ceremony or dinner to celebrate the engagement."},
//         ],
//         [EventThemes[2]['id']]: [
//             {title: 'Arranged Marriage', content: "Many Hindu marriages are arranged by families, where both families meet to discuss the marriage."},
//             {title: 'Engagement (Nischitartham)', content: "An engagement ceremony is held, where the couple exchanges rings and gifts."},
//         ],
//         [EventThemes[3]['id']]: [
//             {title: 'Proposal', content: "The groom proposes to the bride, often with a ring."},
//             {title: 'Engagement', content: "The couple may hold an engagement ceremony or party."},
//         ],
//         [EventThemes[4]['id']]: [
//             {title: 'Proposal', content: "The groom proposes to the bride, often with a ring."},
//             {title: 'Engagement', content: "Some couples hold an engagement ceremony or party."},
//         ]
//     },
//     [PhaseLists[1]]: {
//         [EventThemes[0]['id']]: [
//             {title: 'Marriage Course', content: "Both bride and groom must attend a pre-marriage course (Kursus Pra-Perkahwinan Islam)."},
//             {title: 'Application', content: "The couple must apply for marriage at the State Islamic Religious Department."},
//             {title: 'Permission', content: "The bride’s wali (guardian) gives permission for the marriage."},
//             {title: 'Health Check-Up', content: "A premarital HIV screening test is mandatory."},
//         ],
//         [EventThemes[1]['id']]: [
//             {title: 'Choosing an Auspicious Date', content: "A feng shui master is consulted to select the most auspicious date for the wedding."},
//             {title: 'Preparation', content: "The couple prepares for the wedding, which includes purchasing wedding attire and sending out invitations."},
//         ],
//         [EventThemes[2]['id']]: [
//             {title: 'Pre-Wedding Rituals', content: "This may include a Ganesh Puja to remove obstacles and a mehendi (henna) ceremony for the bride."},
//             {title: 'Choosing an Auspicious Date', content: "The wedding date is selected based on astrological charts."},
//         ],
//         [EventThemes[3]['id']]: [
//             {title: 'Pre-Marriage Counseling', content: "Some denominations require the couple to attend pre-marriage counseling with their priest or pastor."},
//             {title: 'Banns of Marriage', content: "The marriage intention (banns) is announced in church for three consecutive Sundays."},
//             {title: 'Wedding Preparations', content: "The couple arranges the wedding ceremony, which typically takes place in a church."},
//         ],
//         [EventThemes[4]['id']]: [
//             {title: 'Monk\'s Blessing', content: "The couple may seek blessings from monks before the wedding day."},
//             {title: 'Choosing a Date', content: "An auspicious date is selected, often based on lunar calendars."},
//         ]
//     },
//     [PhaseLists[2]]: {
//         [EventThemes[0]['id']]: [
//             {title: 'Nikah Ceremony', content: "The marriage contract (Aqad Nikah) is signed in the presence of the wali, witnesses, and a religious officer (kadi)."},
//             {title: 'Reception', content: "A wedding reception is usually held after the nikah, where family and friends celebrate the union."},
//         ],
//         [EventThemes[1]['id']]: [
//             {title: 'Tea Ceremony', content: "The couple serves tea to their elders as a sign of respect."},
//             {title: 'Banquet', content: "A grand wedding banquet is held, involving multiple courses and toasts."},
//         ],
//         [EventThemes[2]['id']]: [
//             {title: 'Kanyadaan', content: "The bride's father gives his daughter to the groom in marriage."},
//             {title: 'Saptapadi (Seven Steps)', content: "The couple takes seven steps around a sacred fire, making vows at each step."},
//             {title: 'Tying the Thali', content: "The groom ties a sacred thread or necklace (thali) around the bride’s neck, symbolizing their marriage."},
//         ],
//         [EventThemes[3]['id']]: [
//             {title: 'Church Wedding', content: "The ceremony includes hymns, scripture readings, vows, the exchange of rings, and a blessing by the priest or pastor."},
//             {title: 'Signing the Register', content: "The couple signs the marriage register in front of witnesses."},
//         ],
//         [EventThemes[4]['id']]: [
//             {title: 'Simple Ceremony', content: "The ceremony may include chanting, offering prayers to Buddha, and receiving blessings from monks."},
//             {title: 'Tea Ceremony', content: "A tea ceremony may be performed to honor the couple’s elders."},
//         ]
//     },
//     [PhaseLists[3]]: {
//         [EventThemes[0]['id']]: [
//             {title: 'Registration', content: "The marriage must be registered with the State Islamic Religious Department."},
//             {title: 'Marriage Certificate', content: "The couple receives a marriage certificate (Sijil Perkahwinan)."},
//             {title: 'Name Change', content: "The bride may choose to change her last name, though this is not mandatory in Islamic law."},
//         ],
//         [EventThemes[1]['id']]: [
//             {title: 'Registration', content: "The marriage must be registered with the Registrar of Marriages."},
//             {title: 'Name Change', content: "The bride may choose to adopt her husband's surname."},
//             {title: 'Post-Wedding Customs', content: "Some couples observe post-wedding customs such as returning to the bride's home three days after the wedding."},
//         ],
//         [EventThemes[2]['id']]: [
//             {title: 'Registration', content: "The marriage must be registered with the Registrar of Marriages."},
//             {title: 'Name Change', content: "The bride may choose to adopt her husband's surname."},
//             {title: 'Reception', content: "A wedding reception is often held after the ceremony, where the couple celebrates with friends and family."},
//         ],
//         [EventThemes[3]['id']]: [
//             {title: 'Registration', content: "The marriage is registered with the Registrar of Marriages."},
//             {title: 'Marriage Certificate', content: "The couple receives a marriage certificate."},
//             {title: 'Name Change', content: "The bride may choose to take her husband's surname."},
//         ],
//         [EventThemes[4]['id']]: [
//             {title: 'Registration', content: "The marriage must be registered with the Registrar of Marriages."},
//             {title: 'Name Change', content: "The bride may choose to adopt her husband's surname."},
//             {title: 'Reception', content: "A wedding reception may be held after the ceremony."},
//         ]
//     }
// }


// export const EventLists = {
//     "theme-1": {
//         "proposal": [
//             { "title": 'Discussion', "formName": 'muslim_discussion' },
//             { "title": 'Engagement', "formName": 'muslim_engagement' },
//         ],
//         "before": [
//             { "title": 'Marriage Course', "formName": 'muslim_marriage_course' },
//             { "title": 'Application', "formName": 'muslim_application' },
//             { "title": 'Permission', "formName": 'muslim_permission' },
//             { "title": 'Health Check-Up', "formName": 'muslim_health_checkup' },
//         ],
//         "wedding_ceremony": [
//             { "title": 'Nikah Ceremony', "formName": 'muslim_nikah_ceremony' },
//             { "title": 'Reception', "formName": 'muslim_reception' },
//         ],
//         "after": [
//             { "title": 'Registration', "formName": 'muslim_registration' },
//             { "title": 'Marriage Certificate', "formName": 'muslim_marriage_certificate' },
//             { "title": 'Name Change', "formName": 'muslim_name_change' },
//         ],
//     },
//     "theme-2": {
//         "proposal": [
//             { "title": 'Betrothal', "formName": 'chinese_betrothal' },
//             { "title": 'Engagement', "formName": 'chinese_engagement' },
//         ],
//         "before": [
//             { "title": 'Choosing an Auspicious Date', "formName": 'chinese_auspicious_date' },
//             { "title": 'Preparing Registration', "formName": 'chinese_preparing_registration' },
//         ],
//         "wedding_ceremony": [
//             { "title": 'Tea Ceremony', "formName": 'chinese_tea_ceremony' },
//             { "title": 'Banquet', "formName": 'chinese_banquet' },
//         ],
//         "after": [
//             { "title": 'Post-Wedding Customs', "formName": 'chinese_post_wedding_customs' },
//         ],
//     },
//     "theme-3": {
//         "proposal": [
//             { "title": 'Arranged Marriage', "formName": 'hindu_arranged_marriage' },
//             { "title": 'Engagement', "formName": 'hindu_engagement' },
//         ],
//         "before": [
//             { "title": 'Pre-Wedding Rituals', "formName": 'hindu_pre_wedding_rituals' },
//             { "title": 'Choosing an Auspicious Date', "formName": 'hindu_auspicious_date' },
//             { "title": 'Registration', "formName": 'hindu_registration' },
//         ],
//         "wedding_ceremony": [
//             { "title": 'Kanyadaan', "formName": 'hindu_kanyadaan' },
//             { "title": 'Saptapadi', "formName": 'hindu_saptapadi' },
//             { "title": 'Tying the Thali', "formName": 'hindu_tying_thali' },
//         ],
//         "after": [
//             { "title": 'Reception', "formName": 'hindu_reception' },
//         ],
//     },
//     "theme-4": {
//         "proposal": [
//             { "title": 'Proposal', "formName": 'christian_proposal' },
//             { "title": 'Engagement', "formName": 'christian_engagement' },
//         ],
//         "before": [
//             { "title": 'Pre-Marriage Counseling', "formName": 'christian_pre_marriage_counseling' },
//             { "title": 'Banns of Marriage', "formName": 'christian_banns_of_marriage' },
//             { "title": 'Wedding Preparations', "formName": 'christian_wedding_preparations' },
//             { "title": 'Registration', "formName": 'christian_registration' },
//         ],
//         "wedding_ceremony": [
//             { "title": 'Church Wedding', "formName": 'christian_church_wedding' },
//             { "title": 'Signing the Registration', "formName": 'christian_signing_registration' },
//         ],
//         "after": [
//             { "title": 'Reception', "formName": 'christian_reception' },
//         ],
//     },
//     "theme-5": {
//         "proposal": [
//             { "title": 'Proposal', "formName": 'japanese_proposal' },
//             { "title": 'Introduction of Families', "formName": 'japanese_introduction_families' },
//         ],
//         "before": [
//             { "title": 'Engagement Ceremony', "formName": 'japanese_engagement_ceremony' },
//             { "title": 'Selecting a Date', "formName": 'japanese_selecting_date' },
//             { "title": 'Wedding Preparations', "formName": 'japanese_wedding_preparations' },
//             { "title": 'Registration', "formName": 'japanese_registration' },
//         ],
//         "wedding_ceremony": [
//             { "title": 'Shinto Ceremony', "formName": 'japanese_shinto_ceremony' },
//             { "title": 'Western-Style Ceremony', "formName": 'japanese_western_style_ceremony' },
//             { "title": 'Reception', "formName": 'japanese_reception' },
//         ],
//         "after": [
//             { "title": 'Honeymoon', "formName": 'japanese_honeymoon' },
//             { "title": 'Name Change', "formName": 'japanese_name_change' },
//             { "title": 'Post-Wedding Visits', "formName": 'japanese_post_wedding_visits' },
//         ],
//     },
// };