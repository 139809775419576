import React from 'react'
import './manage-wedding-body.styles.scss'
import ManageWeddingMyPlans from '../manage-wedding-my-plans/manage-wedding-my-plans.component'

const ManageWeddingBody = () => {
    return (
        <div className="manage-wedding-body">
            <ManageWeddingMyPlans />         
        </div>
    )
}

export default ManageWeddingBody