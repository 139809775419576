import './manage-wedding-plan-card.styles.scss';
import { Pencil, Plus } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import i18n hook

const ManageWeddingPlanCard = ({ to, phase, phaseTitle, eventList = [], total = 0 }) => {
    const { t } = useTranslation(); // Hook to get translations

    return (
        <Link to={to}>
            <div className={`card ${'phase-' + phase}`}>
                <div className="card-header">
                    <div className="title">{phaseTitle}</div>
                    <div className="icon-container"><Pencil className="icon" /></div>
                </div>
                <div className="card-body">
                    {
                        eventList.length ? (
                            <div className="event-grid">
                                {
                                    eventList &&
                                    eventList.map(event => (
                                        <p className="event" key={event.title}>{event.title} <small className="theme">{event.theme}</small></p>
                                    ))
                                }
                            </div>
                        ) : (
                            <div className="empty">
                                <div className="icon-container"><Plus className='icon' /></div> {t('add_event')}
                            </div>
                        )
                    }
                </div>
                <div className="card-footer">{t('budget')} <small>(RM)</small>: {total}</div>
            </div>
        </Link>
    );
}

export default ManageWeddingPlanCard;
