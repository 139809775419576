import { Link } from 'react-router-dom';
import { PhaseLists } from '../../data/events';
import './manage-wedding-schedule.styles.scss';
import React, { useEffect, useState } from 'react'

// This is the list for phaseTheme to change the card background colour
const PhaseList = {
    proposal: PhaseLists[0],
    before: PhaseLists[1],
    wedding: PhaseLists[2],
    after: PhaseLists[3],
}
// This is the list for phase label in the card
const PhaseTitles = {
    proposal: 'proposal',
    before: 'before wedding',
    wedding: 'wedding ceremony',
    after: 'after wedding',
}

// sample list 
const SampleList = [
    {
        toPath: `${PhaseList.proposal}/${'muslim-engagement'}`,
        phaseTheme: PhaseList.proposal, day:'Wednesday', dateDay: '01', dateMonth: 'Jan', 
        phaseTitle:PhaseTitles.proposal, event: 'Engagement (Nischitartham)', theme:'Muslim', time:'00:00 - 23:00', 
        placeName: 'Place name A', address: 'No. 15, Jalan Bunga Raya 3, Taman Sri Indah, 47400 Petaling Jaya, Selangor, Malaysia.', 
        services: [{title: 'Ring', price:100.00}, {title: 'photographer', price: 800.00}], totalPrice: 900.00,
        status: 'Ready'
    },
    {
        toPath: `${PhaseList.before}/${'muslim-discussion'}`,
        phaseTheme: PhaseList.before, day:'Thursday', dateDay: '10', dateMonth: 'Jan', 
        phaseTitle:PhaseTitles.before, event: 'Discussion', theme:'Muslim', time:'00:00 - 23:00', 
        placeName: 'Place name B', address: 'No. 15, Jalan Bunga Raya 3, Taman Sri Indah, 47400 Petaling Jaya, Selangor, Malaysia.', 
        services: [], 
        status: 'Ready'
    },
]


const ManageWeddingSchedule = () => {
    const [eventList, setEventList] = useState([]);

    useEffect(()=>{
        setEventList(SampleList);
    }, [eventList])
    
    return (
        <div className="manage-wedding-schedule">
            <h4 className="main-title">Schedule</h4>             
            <div className="card-grid-container">

                {
                    eventList &&
                    eventList.length === 0 ? (
                        // show this if there is no event selected
                        <div className="card-container empty"><h4>No plan yet.</h4></div>
                    ) : (
                        eventList.map(eventData=>(
                            /* 
                                link should be ./:phase/:event
                                example: ./proposal/event-id
                            */ 
                            <Link to={`/manage-wedding/${eventData.toPath}`} key={eventData.theme + eventData.event}>
                                <div className={`card-container ${eventData.phaseTheme}`}>
                                    <div className="container-slot" id='date-container'>
                                        <h6 className="card-title">{eventData.day}</h6>
                                        <h4 className="card-date">
                                            <span>{eventData.dateDay}</span>
                                            <span>{eventData.dateMonth}</span>
                                        </h4>
                                    </div>                                        
                                    <span className="vertical-divider" /> {/* ===================================== */}
                                    <div className="container-slot" id='event-container'>
                                        <h6 className="card-title">{eventData.phaseTitle}</h6>
                                        <div className="card-event-container">
                                            <p className="card-event">{eventData.event}</p>
                                            <small className="card-theme">{eventData.theme}</small>
                                        </div>
                                        <small className="card-time">{eventData.time}</small>
                                    </div>                    
                                    <span className="vertical-divider" /> {/* ===================================== */}
                                    <div className="container-slot" id='venue-container'>
                                        <h6 className="card-title">VENUE</h6>
                                        <div className="address-container">
                                            <p className="venue-name">{eventData.placeName}</p>
                                            <small className="venue-address">
                                                {eventData.address}
                                            </small>
                                        </div>
                                    </div>                    
                                    <span className="vertical-divider" /> {/* ===================================== */}
                                    <div className="container-slot" id='service-container'>
                                        <div className="title-container">
                                            <h6 className="card-title">SERVICES</h6>
                                            <small className='price-unit'>( RM )</small>
                                        </div>
                                        <div className="service-item-container">
                                        {
                                            // Loop services array with {title, price} REFER to sample data
                                            eventData.services && eventData.services.length !==0 ? (
                                                eventData.services.map(service=>(
                                                    <div className="service-container" key={service.title}>
                                                        <div className="service-item">
                                                            <small className="item">{service.title}</small>
                                                            <small className="price">{service.price}</small>
                                                        </div>
                                                    </div>
                                                ))
                                            ):(
                                                // if no service selected then show this
                                                <div className="service-container">
                                                    <div className="service-item">
                                                        <small className="item">No item added</small>
                                                        <small className="price">0.00</small>
                                                    </div>
                                                </div>                                            
                                            )                                        
                                        }
                                        </div>

                                        {/* total price */}
                                        <div className="total-container">
                                            <small className="item">Total:</small>
                                            <small className="price">{eventData.totalPrice || '0.00'}</small>
                                        </div>                                    

                                        
                                    </div>                    
                                    {/* the current event status, example: incomplete, ready ... */}
                                    <span className="vertical-divider" /> {/* ===================================== */}
                                    <div className="container-slot" id='status-container'>
                                        <h6 className="card-title">STATUS</h6>
                                        <b>{eventData.status}</b>
                                    </div>                    
                                </div>
                            </Link>
                        ))
                    )
                }                

                {/* THIS IS SAMPLE */}
                <div className="card-container proposal">
                    <div className="container-slot" id='date-container'>
                        <h6 className="card-title">WEDNESDAY</h6>
                        <h4 className="card-date">
                            <span>01</span>
                            <span>JAN</span>
                        </h4>
                    </div>                                        
                    <span className="vertical-divider" /> {/* ===================================== */}
                    <div className="container-slot" id='event-container'>
                        <h6 className="card-title">PHASE TITLE</h6>
                        <div className="card-event-container">
                            <p className="card-event">Engagement (Nischitartham)</p>
                            <small className="card-theme">Theme</small>
                        </div>
                        <small className="card-time">00:00 - 24:00</small>
                    </div>                    
                    <span className="vertical-divider" /> {/* ===================================== */}
                    <div className="container-slot" id='venue-container'>
                        <h6 className="card-title">VENUE</h6>
                        <div className="address-container">
                            <p className="venue-name">Place name</p>
                            <small className="venue-address">
                                <div>No. 15, Jalan Bunga Raya 3, Taman Sri Indah,</div>
                                <div>47400 Petaling Jaya, Selangor, Malaysia.</div>
                            </small>
                        </div>
                    </div>                    
                    <span className="vertical-divider" /> {/* ===================================== */}
                    <div className="container-slot" id='service-container'>
                        <div className="title-container">
                            <h6 className="card-title">SERVICES</h6>
                            <small className='price-unit'>( RM )</small>
                        </div>
                        <div className="service-container">
                            <div className="service-item">
                                <small className="item">No item added</small>
                                <small className="price">-</small>
                            </div>
                        </div>

                        {/* if no service selected then show this */}
                        <div className="total-container">
                            <small className="item">No item added</small>
                            <small className="price">0.00</small>
                        </div>
                    </div>                    
                    {/* the current event status, example: incomplete, ready ... */}
                    <span className="vertical-divider" /> {/* ===================================== */}
                    <div className="container-slot" id='status-container'>
                        <h6 className="card-title">STATUS</h6>
                        <b>Ready</b>
                    </div>                    
                </div>

            </div>           
        </div>
    )
}

export default ManageWeddingSchedule